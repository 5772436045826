.mat-step-header {
  font-family: var(--font) !important;
  .mat-step-icon-selected,
  .mat-step-icon-state-done,
  .mat-step-icon-state-edit {
    background-color: var(--primary);
    font-size: 16px;
  }
  .mat-step-text-label {
    font-size: 16px;
  }
}
