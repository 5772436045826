.multiselect {
  .multiselect-dropdown {
    .dropdown-btn {
      border: var(--bs-border-width) solid var(--bs-border-color) !important;
      border-radius: var(--bs-border-radius) !important;
      font-size: 14px;
      font-family: var(--font);
      min-height: 38px;
      display: flex !important;
      align-items: center !important;

      .selected-item-container {
        .selected-item {
          border: 1px solid var(--primary) !important;
          background: var(--primary) !important;
          font-size: 10px;
          font-family: var(--font);
          margin: 0 !important;
          padding: 2px 5px !important;
        }
      }
    }

    ul {
      &.item2 {
        // height: 107px;
        // overflow: hidden;
        // overflow-y: scroll;
        .no-data {
          h5 {
            font-size: 14px;
          }
        }
      }
    }
    .dropdown-list {
      ul {
        font-size: 12px;
        font-family: var(--font);
        max-height: 120px !important;
        .multiselect-item-checkbox {
          input + div {
            &:before {
              color: var(--primary);
              border: 2px solid var(--primary);
            }
          }
          input {
            &:checked + div {
              &:before {
                -webkit-animation: auto;
                animation: auto;
                background: var(--primary);
              }
            }
          }
        }
      }
    }
  }

  .multiselect-dropdown:active,
  .multiselect-dropdown:focus {
    border-radius: var(--bs-border-radius) !important;
    border-color: #dee2e6;
    box-shadow: 0 0 6px 0 rgba(13, 110, 253, 0.25);
  }
}

ng-multiselect-dropdown {
  &.form-control-danger {
    div.multiselect-dropdown {
      border-radius: var(--bs-border-radius) !important;
      border-color: #dee2e6 !important;
      box-shadow: 0 0 4px 0 #dc3545 !important;
    }
  }
}
